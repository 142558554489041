import { linkPaymentData } from '../data/linkPayment';
import { RootState } from '../store/config';
import { useAppSelector } from '../store/hooks';
import { BeginPay } from '../types/beginPay.types';
import { VerticalType } from '../types/enums';
import { Issuer } from '../types/paymentMethod.types';
import { User } from '../types/user.types';
import usePaymentMethods from './queries/usePaymentMethods';
import useBeginPay from './useBeginPay';

export default function useRestaurantStepper() {
  const { commerce } = useAppSelector((state: RootState) => state.commerce);
  const { amount, currency, tipPercentage, invoice, country } = useAppSelector(
    (state: RootState) => state.debt.restaurantsDebt,
  );
  const { data: paymentMethods } = usePaymentMethods(VerticalType.RESTAURANTS, commerce?.commerceId, country);
  const pixIssuer: Issuer | undefined = paymentMethods?.issuers[0];
  const { submitBeginPay, isSubmitting, snackbarOpen, errorMessage, closeSnackbar } = useBeginPay();
  const user: User | null = useAppSelector((state: RootState) => state.user);

  const submitPayment = () => {
    if (pixIssuer && commerce?.restaurantEmployee) {
      const beginPayValues: BeginPay = {
        verticalType: VerticalType.RESTAURANTS,
        commerceId: commerce?.commerceId,
        issuerConfigurationId: pixIssuer?.issuerConfigurationId,
        amount: amount || 0,
        currency,
        tipPercentage: +tipPercentage || 0,
        country: country,
        data: {
          restaurantEmployee: commerce.restaurantEmployee,
          restaurantName: commerce?.name,
          invoice: invoice,
          email: user.email || linkPaymentData.email,
        },
      };
      submitBeginPay(beginPayValues);
    }
  };

  return {
    submitPayment,
    isSubmitting,
    snackbarOpen,
    errorMessage,
    closeSnackbar,
    amount,
    invoice,
    tipPercentage,
    currency,
  };
}
