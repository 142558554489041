import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/_variables.module.scss';
import { PaymentResponse, RestaurantPaymentSuccess } from '../../../types/paymentResult.types';
import { amountHelper } from '../../../utils/amount.helper';
import { formatCustomDate, styles } from '../../../utils/receipt.helper';
import CommonReceiptData from '../../CommonReceiptData';

interface RestaurantReceiptDetailProps {
  paymentSuccess: PaymentResponse | null;
}

function RestaurantReceiptDetail({ paymentSuccess }: RestaurantReceiptDetailProps) {
  const { t } = useTranslation();
  const data = paymentSuccess?.data as RestaurantPaymentSuccess;

  const subtotal =
    data?.amount || data?.tipAmount || data?.commissionAmount || data?.fixedCommissionAmount
      ? data?.amount + data?.tipAmount + data?.commissionAmount + data?.fixedCommissionAmount
      : 0;

  return (
    <>
      <View style={styles.detailHeader}>
        <Text style={styles.headerTitle}>{t('receipt-detail.title')}</Text>
        <Text style={styles.headerSubTitle}>{data?.restaurantName?.toUpperCase()}</Text>
        <Text style={styles.text}>{t('verticals.restaurants.receipt-detail.subtitle')}</Text>
        <View style={styles.gap} />
        <Text style={styles.text}>{data?.paymentId}</Text>
        <Text style={styles.text}>{`${t('verticals.restaurants.receipt-detail.employee')} ${
          data?.restaurantEmployee
        }`}</Text>
        <Text style={{ color: colors.placeholderGrey, fontSize: 12 }}>
          {formatCustomDate(data?.date || '')}
        </Text>
      </View>
      <View style={styles.gap} />
      <View style={styles.horizontalChunkBorder}>
        <Text style={styles.detailText}>{t('verticals.restaurants.receipt-detail.invoice')}</Text>
        <Text>{data?.invoice}</Text>
      </View>
      <CommonReceiptData
        data={data}
        country={paymentSuccess?.country}
        children={
          <>
            <View style={styles.horizontalChunk}>
              <Text>{t('verticals.restaurants.receipt-detail.tip')}&nbsp;</Text>
              <Text>{amountHelper.getAmountFormatted(data?.tipAmount) || '0'}</Text>
            </View>
            <View style={styles.horizontalChunk}>
              <Text>{t('verticals.restaurants.receipt-detail.subtotal')}&nbsp;</Text>
              <Text>{amountHelper.getAmountFormatted(subtotal)}</Text>
            </View>
            <View style={styles.gap} />
            {data?.returnedVatAmount && (
              <View style={styles.horizontalChunk}>
                <Text>{`${t(
                  'verticals.restaurants.receipt-detail.taxDiscount',
                )} ${data?.financialInclusionLaw.replace(/\D/g, '')}`}</Text>
                <Text>{`- ${amountHelper.getAmountFormatted(data?.returnedVatAmount)}`}</Text>
              </View>
            )}
          </>
        }
      />
    </>
  );
}

export default RestaurantReceiptDetail;
