import * as Sentry from '@sentry/react';
import { GetPaymentAmount } from '../types/amount.types';
import axios from './axios';

const getPaymentAmount = async ({
  verticalType,
  commerceId,
  ...amountParametersRequest
}: GetPaymentAmount) => {
  try {
    const res = await axios.post(
      `/verticals/${verticalType}/commerce/${commerceId}/payment-amount`,
      amountParametersRequest,
    );

    return res.data.data;
  } catch (err: any) {
    Sentry.captureException(err);
    throw err;
  }
};

export const amountService = { getPaymentAmount };
