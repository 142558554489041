import { useQuery } from '@tanstack/react-query';
import { paymentMethodsService } from '../../services/paymentMethods';
import { VerticalType } from '../../types/enums';
import { PaymentMethods } from '../../types/paymentMethod.types';
import queryKeys from '../../utils/query.helper';

function usePaymentMethods(verticalType?: VerticalType, commerceId?: number, country?: string) {
  return useQuery<PaymentMethods | undefined>({
    queryKey: queryKeys.paymentMethods.list({ verticalType, commerceId, country }),
    refetchOnWindowFocus: false,
    queryFn: () =>
      verticalType &&
      paymentMethodsService.getPaymentMethods({ verticalType, commerceId, country: country || 'URY' }),
    enabled: !!verticalType && !!commerceId && !!country && country !== 'Seleccionar',
  });
}

export default usePaymentMethods;
