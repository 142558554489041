import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ExpensesPaymentStepper from '../components/Verticals/Expenses/PaymentStepper';
import HotelsPaymentStepper from '../components/Verticals/Hotels/PaymentStepper';
import RestaurantsPaymentStepper from '../components/Verticals/Restaurants/PaymentStepper';
import ServicesPaymentStepper from '../components/Verticals/Services/PaymentStepper';
import TravelsPaymentStepper from '../components/Verticals/Travels/PaymentStepper';
import usePaymentStepper from '../hooks/usePaymentStepper';
import { RootState } from '../store/config';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { VerticalType } from '../types/enums';
import { User } from '../types/user.types';
import { Vertical } from '../types/verticals.type';
// Remove the incorrect import

function PaymentStepper() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { isPaymentLink } = usePaymentStepper();
  const user: User = useAppSelector((state: RootState) => state.user);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const verticalTypeParam = params.verticalType?.toUpperCase() as VerticalType;
  const isRestaurant: boolean =
    vertical?.verticalType === VerticalType.RESTAURANTS || verticalTypeParam === VerticalType.RESTAURANTS;

  useEffect(() => {
    if (!isPaymentLink && !user.userName && !isRestaurant)
      navigate(`/${vertical?.verticalType.toLocaleLowerCase()}/login`);
  }, [isPaymentLink, navigate, user.userName, vertical?.verticalType, isRestaurant]);

  if (!isRestaurant) {
    window.addEventListener('unload', () => {
      dispatch(resetCommerce());
      dispatch(resetDebtState());
      dispatch(resetStep());
    });
  }

  const renderVerticalPaymentStepper = () => {
    switch (vertical?.verticalType) {
      case VerticalType.EXPENSES:
        return <ExpensesPaymentStepper />;
      case VerticalType.HOTELS:
        return <HotelsPaymentStepper />;
      case VerticalType.SERVICES:
        return <ServicesPaymentStepper />;
      case VerticalType.TRAVEL_AGENCY:
        return <TravelsPaymentStepper />;
      case VerticalType.RESTAURANTS:
        return <RestaurantsPaymentStepper />;
      default:
        throw new Error(`Invalid vertical type: ${vertical?.verticalType}`);
    }
  };

  return <>{renderVerticalPaymentStepper()}</>;
}

export default PaymentStepper;
