import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { linkPaymentData } from '../../../data/linkPayment';
import usePaymentMethods from '../../../hooks/queries/usePaymentMethods';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import useRestaurantStepper from '../../../hooks/useRestaurantStepper';
import useWindowSize from '../../../hooks/useWindowSize';
import { RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AmountState } from '../../../store/slices/amount.slice';
import { resetDebtState } from '../../../store/slices/debt.slice';
import { setStep } from '../../../store/slices/step.slice';
import { Commerce } from '../../../types/commerce.type';
import { RestaurantsDebt } from '../../../types/debt.types';
import { PaymentStep, VerticalType, mutationKeys } from '../../../types/enums';
import { Issuer } from '../../../types/paymentMethod.types';
import { User } from '../../../types/user.types';
import { Vertical } from '../../../types/verticals.type';
import { amountHelper } from '../../../utils/amount.helper';
import { commerceHelper } from '../../../utils/commerce.helper';
import Header from '../../Header';
import ConfirmEmailDialog from '../../Stepper/ConfirmEmailDialog';
import EditRestaurantDialog from './EditRestaurantDialog';

interface RestaurantsHeaderProps {
  amount: number;
  invoice: string;
}

function RestaurantsHeader({ amount, invoice }: RestaurantsHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { step, commerceIdentifierParam, isPaymentLink, isSubmitDisabled } = usePaymentStepper();
  const { submitPayment } = useRestaurantStepper();
  const { isMobile } = useWindowSize();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isEditRestaurantDialogOpen, setIsEditRestaurantDialogOpen] = useState<boolean>(false);
  const [isConfirmEmailDialogOpen, setIsConfirmEmailDialogOpen] = useState<boolean>(false);
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const selectedIssuer: Issuer | null = useAppSelector((state: RootState) => state.issuer.issuer);
  const debt: RestaurantsDebt = useAppSelector((state: RootState) => state.debt.restaurantsDebt);
  const { amount: amountDetail }: AmountState = useAppSelector((state: RootState) => state.amount);
  const user: User | null = useAppSelector((state: RootState) => state.user);

  const restaurantId: number | undefined = commerceHelper.findCommerceIdByName(commerce?.name, commerces);
  const commissionText = amountHelper.getCommissionText(
    selectedIssuer?.commission,
    amountHelper.getFixedCommission(debt.currency, selectedIssuer),
    debt.currency,
    selectedIssuer?.gatewayIssuerId,
    VerticalType.RESTAURANTS,
  );

  const { data: paymentMethods } = usePaymentMethods(
    VerticalType.RESTAURANTS,
    commerce?.commerceId,
    debt.country,
  );

  const isGettingPaymentAmount = !!useIsMutating({ mutationKey: mutationKeys.getPaymentAmount });
  const isBeginnignPay = !!useIsMutating({ mutationKey: mutationKeys.beginPay });
  const isNoPaymentMethod = step === PaymentStep.INFORMATION && paymentMethods?.issuers.length === 0;

  const totalFormattedWithCurrency =
    step !== PaymentStep.INFORMATION
      ? amountHelper.getRestaurantTotalFormatted(debt.currency, amountDetail) || '-'
      : undefined;

  const goBack = () => {
    if (step === PaymentStep.PAYMENT_METHODS) {
      if (isMobile) {
        setOpenDialog(!openDialog);
      }
      dispatch(setStep(PaymentStep.INFORMATION));
    } else if (step === PaymentStep.INFORMATION) {
      handleEditRestaurantDialogToggle();
    } else {
      dispatch(setStep(step - 1));
    }
  };

  const handleEditRestaurantDialogToggle = () => {
    setIsEditRestaurantDialogOpen(!isEditRestaurantDialogOpen);
  };

  const handleConfirmEmailDialogToggle = () => {
    setIsConfirmEmailDialogOpen(!isConfirmEmailDialogOpen);
  };

  const handleDialogContinue = () => {
    navigate(
      `/${VerticalType.RESTAURANTS.toLowerCase()}${isPaymentLink ? `/${commerceIdentifierParam}` : ''}/login`,
    );
    dispatch(resetDebtState());
  };

  const handleSubmit =
    step === PaymentStep.EMAIL
      ? user.email !== null && user.email !== linkPaymentData.email
        ? submitPayment
        : handleConfirmEmailDialogToggle
      : step === PaymentStep.PAYMENT_METHODS
      ? () => dispatch(setStep(PaymentStep.EMAIL))
      : undefined;

  return (
    <>
      <Header
        form="restaurants-debt-form"
        goBack={goBack}
        submit={handleSubmit}
        isLoading={isBeginnignPay || isGettingPaymentAmount}
        title={`${t('verticals.restaurants.payment-steps.title')} Pix/${commerce?.name}`}
        commissionText={commissionText}
        submitDisabled={!amount || !invoice || isBeginnignPay || isGettingPaymentAmount || isNoPaymentMethod}
        buttonText={
          step === PaymentStep.INFORMATION
            ? t('verticals.restaurants.payment-steps.edit-button')
            : t('verticals.step-labels.back-button')
        }
        hasPIXLogo
        commerceId={restaurantId || commerce?.commerceId}
        verticalType={VerticalType.RESTAURANTS}
        totalAmount={totalFormattedWithCurrency}
      />
      <EditRestaurantDialog
        isDialogOpen={isEditRestaurantDialogOpen}
        handleCloseDialog={handleEditRestaurantDialogToggle}
        title={t('verticals.restaurants.edit-dialog.employee-title')}
        contentText={t('verticals.restaurants.edit-dialog.employee-content')}
        onContinue={handleDialogContinue}
      />
      <ConfirmEmailDialog
        isDialogOpen={isConfirmEmailDialogOpen}
        handleCloseDialog={handleConfirmEmailDialogToggle}
        title={t('verticals.step-labels.email.dialog.title')}
        contentText={t('verticals.step-labels.email.dialog.content')}
        onContinue={submitPayment}
        submitButtonDisabled={isSubmitDisabled}
      />
    </>
  );
}

export default RestaurantsHeader;
