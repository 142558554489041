import * as Sentry from '@sentry/react';
import axios from './axios';

const verifyCaptchaToken = async (captchaToken: string) => {
  try {
    const res = await axios.get(`/captcha/verify/${captchaToken}`);

    return res.data;
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const captchaService = { verifyCaptchaToken };
