import { VerticalType } from '../types/enums';

const queryKeys = {
  verticals: {
    base: ['verticals'],
  },
  paymentMethods: {
    base: ['paymentMethods'],
    list: (props: { verticalType?: VerticalType; commerceId?: number; country?: string }) => [
      'paymentMethods-commerce',
      props,
    ],
  },
};

export default queryKeys;
