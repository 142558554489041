import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactComponent as CurvedArrow } from '../src/assets/curved-arrow.svg';
import { ReactComponent as PayPuntaOrangeLogo } from '../src/assets/logo-pp-orange.svg';
import Message from './components/Message';
import ScrollToTop from './components/ScrollToTop';
import { config } from './config/config';
import Main from './screens/Main';
import { persistor, store } from './store/config';
import colors from './styles/_variables.module.scss';
import './styles/app.scss';
import { pathnameHelper } from './utils/pathname.helper';

const tagManagerConfig = { gtmId: config.trackingId || '' };

const tagManagerArgs = {
  dataLayer: { page: `${pathnameHelper.sanitizePathnameForGtm(window.location.pathname)}` },
  dataLayerName: `${pathnameHelper.sanitizePathnameForGtm(window.location.pathname)}_dataLayer`,
};

TagManager.initialize(tagManagerConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    info: {
      main: '#5A607F',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          font: 'normal normal 300 61px/71px Titillium Web',
        },
        body1: {
          font: 'normal normal normal 14px/17px Rubik',
        },
        body2: {
          font: 'normal normal normal 14px/17px Rubik',
          color: colors.lightGrey,
        },
      },
    },
  },
});

const queryClient = new QueryClient();

function App() {
  const { t } = useTranslation();

  const customFallback = (
    <Message
      id="error-commerce"
      payPuntaLogo={<PayPuntaOrangeLogo />}
      title={t('errors.generic')}
      text=""
      retryIcon={<CurvedArrow />}
      retryText={t('errors.retryText')}
      retryUrl="/"
    />
  );

  useEffect(() => TagManager.dataLayer(tagManagerArgs), []);

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      Sentry.captureException(event.reason);
    });

    return () => {
      window.removeEventListener('unhandledrejection', (event) => {
        Sentry.captureException(event.reason);
      });
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <QueryClientProvider client={queryClient}>
              {config.env === 'local' && <ReactQueryDevtools />}
              <Sentry.ErrorBoundary fallback={customFallback}>
                <Main />
              </Sentry.ErrorBoundary>
            </QueryClientProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
