import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppDispatch, RootState, store } from '../store/config';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { resetCommerce } from '../store/slices/commerce.slice';
import { resetDebtState } from '../store/slices/debt.slice';
import { resetStep } from '../store/slices/step.slice';
import { resetUser } from '../store/slices/user.slice';
import { resetVertical } from '../store/slices/vertical.slice';
import { VerticalType } from '../types/enums';
import { User } from '../types/user.types';
import Error from './Error';
import Landing from './Landing';
import Login from './Login';
import MaintenanceScreen from './MaintenanceScreen/MaintenanceScreen';
import PaymentStepper from './PaymentStepper';
import PlexoRedirect from './PlexoRedirect';
import Success from './Success';
import VerticalRoutes from './VerticalRoutes';

const LOCAL_STORAGE_KEY = 'cleared';

function Main() {
  const dispatch: AppDispatch = useAppDispatch();
  const user: User | null = useAppSelector((state: RootState) => state.user);
  const isRestaurant = store.getState().vertical.vertical?.verticalType === VerticalType.RESTAURANTS;

  useEffect(() => {
    const currentTime = new Date().getTime();

    if (user.timeout && currentTime > user.timeout) {
      dispatch(resetUser());
    }
  }, [dispatch, user.timeout]);

  useEffect(() => {
    const purgePersistedData = async () => {
      const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (!storedValue) {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(resetUser());
        !isRestaurant && dispatch(resetCommerce());
        dispatch(resetStep());
        dispatch(resetVertical());
        dispatch(resetDebtState());
        localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
      }
    };

    purgePersistedData();
  }, [dispatch, isRestaurant]);

  return (
    <div className="main">
      <div className="content">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/maintenance" element={<MaintenanceScreen />} />
          <Route path="/error" element={<Error />} />
          <Route path="/success" element={<Success />} />
          <Route path="/plexo/redirect" element={<PlexoRedirect />} />
          <Route element={<VerticalRoutes />}>
            <Route path="/:verticalType" element={<PaymentStepper />} />
            <Route path="/:verticalType/login" element={<Login />} />
            <Route path="/:verticalType/:commerceIdentifier/login" element={<Login />} />
            <Route path="/:verticalType/:commerceIdentifier" element={<PaymentStepper />} />
            <Route path="/:verticalType/:commerceIdentifier/:currency/:amount" element={<PaymentStepper />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default Main;
