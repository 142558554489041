import variables from '../../styles/_variables.module.scss';

function PaymentMethodDefaultIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.2"
      viewBox="0 0 92 52"
      width="92"
      height="52"
      fill="#5a607f"
      style={{ border: `1px solid ${variables.shadowGrey}` }}
    >
      <title>landmark (2)-svg</title>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="m32 13h27v25h-27z" />
        </clipPath>
      </defs>
      <g id="Clip-Path" clip-path="url(#cp1)">
        <g>
          <path
            fill-rule="evenodd"
            d="m59 18.2v1.8c0 0.5-0.3 0.8-0.8 0.8h-25.4c-0.5 0-0.8-0.3-0.8-0.8v-1.8q0-0.2 0.1-0.4 0.2-0.2 0.4-0.3l12.5-4.4q0.2-0.1 0.5-0.1 0.3 0 0.5 0.1l12.5 4.4c0.3 0.1 0.5 0.4 0.5 0.7zm-1.5 0.6l-12-4.2-12 4.2v0.5h24zm1.5 18v0.8c0 0.2-0.2 0.4-0.4 0.4h-26.2c-0.2 0-0.4-0.2-0.4-0.4v-0.8c0-0.2 0.2-0.4 0.4-0.4h1.1v-3.1c0-0.9 0.8-1.6 1.7-1.6h0.5v-9.4h1.5v9.4h4.5v-9.4h1.5v9.4h4.5v-9.4h1.5v9.4h4.5v-9.4h1.5v9.4h0.6c0.9 0 1.7 0.7 1.7 1.6v3.1h1.1c0.2 0 0.4 0.2 0.4 0.4zm-3-3.5q0 0-0.2 0h-20.6q-0.2 0-0.2 0v3.1h21z"
          />
        </g>
      </g>
    </svg>
  );
}

export default PaymentMethodDefaultIcon;
