import { Box } from '@mui/material';
import React from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import { RootState } from '../../../../store/config';
import { useAppSelector } from '../../../../store/hooks';
import { Commerce } from '../../../../types/commerce.type';
import { Vertical } from '../../../../types/verticals.type';
import { commerceHelper } from '../../../../utils/commerce.helper';
import DebtForm from './DebtForm';
import PaymentDetails from './PaymentDetails';

interface PaymentInformationProps {
  setInvoice: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  setTipPercentage: React.Dispatch<React.SetStateAction<number>>;
  currency: string;
  tipPercentage: number;
  setCountry: React.Dispatch<React.SetStateAction<string | undefined>>;
  country: string | undefined;
}

function PaymentInformationStep({
  setInvoice,
  setAmount,
  setTipPercentage,
  currency,
  tipPercentage,
  setCurrency,
  setCountry,
  country,
}: PaymentInformationProps) {
  const { isMobile } = useWindowSize();
  const globalState = useAppSelector((state: RootState) => state);
  const commerce: Commerce | null = globalState.commerce.commerce;
  const vertical: Vertical | null = globalState.vertical.vertical;
  const debtState = globalState.debt.restaurantsDebt;
  const commerces: Commerce[] | undefined = vertical?.commerces;

  const restaurantId: number | undefined = commerceHelper.findCommerceIdByName(commerce?.name, commerces);

  return (
    <Box className="payment-information-step">
      <DebtForm
        setInvoice={setInvoice}
        setAmount={setAmount}
        setTipPercentage={setTipPercentage}
        setCurrency={setCurrency}
        setCountry={setCountry}
        country={country}
        restaurantId={restaurantId || commerce?.commerceId}
      />
      {!isMobile && (
        <div className="detail-column">
          <PaymentDetails
            tipPercentage={tipPercentage}
            currency={currency}
            restaurantId={restaurantId || commerce?.commerceId}
            country={country || debtState.country}
          />
        </div>
      )}
    </Box>
  );
}

export default PaymentInformationStep;
