import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import schemas from '../../../config/schemas';
import { currencies } from '../../../data/currencies';
import { stepsLabels } from '../../../data/paymentSteps';
import usePaymentStepper from '../../../hooks/usePaymentStepper';
import { AppDispatch, RootState } from '../../../store/config';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setCommerce } from '../../../store/slices/commerce.slice';
import { setTravelsDebt } from '../../../store/slices/debt.slice';
import { Commerce } from '../../../types/commerce.type';
import { TravelsDebt } from '../../../types/debt.types';
import { PaymentStep } from '../../../types/enums';
import { Vertical } from '../../../types/verticals.type';
import { commerceHelper } from '../../../utils/commerce.helper';
import FormikAutocompleteField from '../../Formik/FormikAutocompleteField';
import FormikNumberFormat from '../../Formik/FormikNumberFormat';
import FormikSelectField from '../../Formik/FormikSelectField';
import FormikTextField from '../../Formik/FormikTextField';

function PaymentInformation() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useAppDispatch();
  const { setStep, isPaymentLink, updateSubmitDisabled } = usePaymentStepper();
  const vertical: Vertical | null = useAppSelector((state: RootState) => state.vertical.vertical);
  const commerces: Commerce[] | undefined = vertical?.commerces;
  const commerce: Commerce | null = useAppSelector((state: RootState) => state.commerce.commerce);
  const travelsDebt: TravelsDebt = useAppSelector((state: RootState) => state.debt.travelsDebt);
  const { amount: amountParam } = useParams<{
    amount?: string;
    currency?: string;
  }>();
  const amount = amountParam && +amountParam;

  const submitTravelsDebt = (values: TravelsDebt) => {
    const selectedCommerce: Commerce | undefined = commerces?.find(
      (commerce) => commerce.name === values.agency,
    );
    dispatch(setTravelsDebt(values));
    if (selectedCommerce) {
      dispatch(setCommerce(selectedCommerce));
    }
    dispatch(setStep(PaymentStep.PAYMENT_METHODS));
  };

  const initialValues: TravelsDebt = {
    agency: commerce?.name || null,
    booking: travelsDebt.booking || null,
    currency: 'USD',
    amount: amount || travelsDebt.amount || null,
    // TODO: change to 'Seleccionar' when the country can be selected
    country: 'URY',
  };

  if (!commerce && isPaymentLink) return null;

  return (
    <Box id="travels-form-container">
      <h6>{t(stepsLabels[0])}</h6>
      <Formik
        initialValues={initialValues}
        validationSchema={schemas.TravelsDebtSchema}
        onSubmit={submitTravelsDebt}
        enableReinitialize
        isInitialValid={schemas.TravelsDebtSchema.isValidSync(initialValues)}
      >
        {({ values, isValid }) => {
          updateSubmitDisabled(!isValid);

          return (
            <Form id="travels-debt-form">
              <FormikAutocompleteField
                name="agency"
                fullWidth
                label={t('verticals.travel_agency.debt-form.labels.agency')}
                options={commerceHelper.sortVerticalCommerces(commerces) || []}
                placeholder={t('verticals.travel_agency.debt-form.placeholders.agency')}
                value={values.agency || commerce?.name}
                disabled={isPaymentLink && !!commerce?.name}
              />
              <FormikSelectField
                name="currency"
                fullWidth
                label={t('verticals.travel_agency.debt-form.labels.currency')}
                options={[currencies.find((currency) => currency.id === 'USD')!]}
                placeholder={t('verticals.travel_agency.debt-form.placeholders.currency')}
                disabled
              />
              <FormikNumberFormat
                fullWidth
                name="amount"
                label={t('verticals.travel_agency.debt-form.labels.amount')}
                disabled={isPaymentLink && !!amount}
              />
              <FormikTextField
                fullWidth
                name="booking"
                label={t('verticals.travel_agency.debt-form.labels.booking')}
                type="text"
                inputMode="text"
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default PaymentInformation;
