import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { config } from '../config/config';

interface CustomResponse extends AxiosResponse {
  maintenance: boolean;
}

const instance: AxiosInstance = axios.create({ baseURL: config.backendUrl });
const isMaintenanceUrl = window.location.href.includes('maintenance');

instance.interceptors.response.use((response: AxiosResponse<CustomResponse>) => {
  if (response.data.maintenance && !isMaintenanceUrl) window.location.replace('/maintenance');

  return response;
});

export default instance;
